import _objectSpread from "/Users/pitt/study/pmt/pmt.client.fe/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
/**
* pages 业务层配置信息
* @author lizh
* @date 2018-12-3
*/

import build from './build';
var IS_DEV = build.IS_DEV;
var HOST = window.location.host;
var ORIGIN = window.location.origin;
var PATHNAME = window.location.pathname;
var SERACH = window.location.search;
var prodRootMap = {
  'client.pmt.zuanqilai.ink': {
    uploadMediaRoot: 'http://upload.pmt.zuanqilai.ink/',
    uploadRoot: 'http://upload.pmt.zuanqilai.ink/',
    root: 'http://api.pmt.zuanqilai.ink/',
    codeRoot: 'http://api.gsp.feihang.ink/',
    ssoRoot: 'http://purview.pmt.zuanqilai.ink/',
    ssoUrl: 'http://sso.pmt.zuanqilai.ink/'
  },
  'localhost': {
    uploadMediaRoot: 'http://upload.pmt.zuanqilai.ink/',
    uploadRoot: 'http://localhost:10091/',
    root: 'http://localhost:10088/',
    codeRoot: 'http://api.gsp.feihang.ink/',
    ssoRoot: 'http://localhost:10087/',
    // ssoUrl:'https://sso.luckytoken.cc/',
    ssoUrl: '//sso.pmt.zuanqilai.ink/' //ssoUrl: 'http://localhost:5000/',

  }
}; ////

var rootObj = prodRootMap[Object.keys(prodRootMap).find(function (key) {
  return HOST.indexOf(key) === 0;
})] || {
  ssoRoot: 'http://purview.pmt.zuanqilai.ink/',
  ssoUrl: 'http://sso.pmt.zuanqilai.ink/'
};
var DEFAULT_ROOT = '/';

var getRootStr = function getRootStr(rootStr) {
  return rootStr || DEFAULT_ROOT;
};

var PROD = {
  APIS: {
    uploadMediaRoot: getRootStr(rootObj.uploadMediaRoot),
    uploadRoot: getRootStr(rootObj.uploadRoot),
    root: getRootStr(rootObj.root),
    ssoUrl: getRootStr(rootObj.ssoUrl),
    ssoRoot: getRootStr(rootObj.ssoRoot),
    codeRoot: getRootStr(rootObj.codeRoot),
    taskRoot: getRootStr(rootObj.taskRoot)
  }
};
var DEV = {
  APIS: {
    uploadMediaRoot: DEFAULT_ROOT,
    uploadRoot: DEFAULT_ROOT,
    root: DEFAULT_ROOT,
    ssoUrl: DEFAULT_ROOT,
    ssoRoot: DEFAULT_ROOT,
    codeRoot: DEFAULT_ROOT,
    taskRoot: DEFAULT_ROOT
  }
};
var ssoService = encodeURIComponent("".concat(ORIGIN + PATHNAME + SERACH)); // 开发环境权限总开关 false - 关闭权限菜单， true - 开启权限菜单

var devAuthority = true;

var OBJ = _objectSpread(_objectSpread(_objectSpread({
  SUPER_ADMIN: ['45167416@qq.com'],
  IS_DEV: IS_DEV,
  USER_INFO_MOCK: false,
  // 手机登录情况下，登录页面路由地址
  PHONE_LOGGIN_PATH: 'login',
  SSO_PAGE_SERVICE: "".concat(rootObj.ssoUrl, "?service=").concat(ssoService),
  SSO_PAGE_URL: rootObj.ssoRoot,
  SSO_ROOT: rootObj.ssoUrl,
  SSO_LOGOUT_PAGE_SERVICE: "".concat(rootObj.ssoUrl, "?service=").concat(ssoService),
  build: build
}, build.ENV), IS_DEV ? DEV : PROD), {}, {
  // 该系统权限标识，获取请联系 @李宁，2 为测试系统
  // http://wiki.inkept.cn/pages/viewpage.action?pageId=50851740
  AUTH_SYSTEM_ID: 2,
  // 系统中文名称
  SYSTEM_CHINA_NAME: 'Ads admin  ',
  // 系统英文缩写
  SYSTEM_US_NAME: 'Ads',
  // 系统地址
  SYSTEM_URL: 'admin1.pmt.zuanqilai.ink',
  // 权限总开关 false - 关闭权限菜单， true - 开启权限菜单
  authority: IS_DEV ? devAuthority : true,
  // 使用情况监控，获取请联系 @赵通，-1 则全局关闭监控
  LOG_SYSTEM_SOURCE: -1
});

export default OBJ;